/* eslint-disable jsx-a11y/label-has-associated-control */
import { MenuItem } from '@mui/material'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Select from '@mui/material/Select'
import { useTheme } from '@wandb/ui'
import { Flex } from 'common/Flex'
import { LicenseFlagsInput } from 'common/license/LicenseFlagsInput'
import { endOfDay, format, parse, parseISO } from 'date-fns/esm'
import {
  DeploymentDocument,
  DeploymentQuery,
  LicenseCreateInput,
  useCreateLicenseMutation,
  useDeploymentQuery
} from 'generated/deploy'
import first from 'lodash/first'
import pick from 'lodash/pick'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button, Checkbox, Form, Grid, Header } from 'semantic-ui-react'

type LicenseFormState = Omit<
  LicenseCreateInput,
  'deploymentId' | 'expiresAt'
> & {
  expiresAt: string
  trial: boolean
}

const useCreateLicenseForm = (
  deployment?: DeploymentQuery['deployment'],
  onSubmit?: () => Promise<unknown> | unknown,
  onClose?: DialogProps['onClose']
) => {
  const form = useForm<LicenseFormState>({
    defaultValues: { expiresAt: format(new Date(), 'yyyy-MM-dd'), flags: [] }
  })
  const { handleSubmit, setValue, reset } = form

  // Repopulate form with previous license formation
  const activeLicense = first(deployment?.licenses?.nodes)
  useEffect(() => {
    if (activeLicense != null) {
      const expiresAtDate = parseISO(activeLicense.expiresAt)
      reset({
        ...pick(activeLicense, [
          'maxStorageGb',
          'maxTeams',
          'maxUsers',
          'maxViewOnlyUsers',
          'maxRegisteredModels',
          'trial'
        ]),
        flags: activeLicense.flags.map(f => f.flag),
        expiresAt: format(expiresAtDate, 'yyyy-MM-dd')
      })
    }
  }, [activeLicense, setValue, reset])

  const [createLicense, { loading }] = useCreateLicenseMutation()

  const onFormSubmit = handleSubmit(async ({ expiresAt, ...data }, event) => {
    if (deployment == null) return
    if (deployment.id == null) return
    const expiredAtDate = parse(expiresAt, 'yyyy-MM-dd', new Date())
    await createLicense({
      variables: {
        data: {
          ...data,
          expiresAt: endOfDay(expiredAtDate),
          deploymentId: deployment.id
        }
      },
      refetchQueries: [
        { query: DeploymentDocument, variables: [{ id: deployment.id }] }
      ]
    })
    await onSubmit?.()
    onClose?.(event as any, 'escapeKeyDown')
  })

  return { ...form, loading, onSubmit: onFormSubmit }
}

export const DeploymentDialogLicense: React.FC<
  DialogProps & {
    deploymentId: string
    onLicenseCreate?: () => Promise<void> | void
  }
> = ({ deploymentId, onLicenseCreate, ...props }) => {
  const { data, loading: queryLoading } = useDeploymentQuery({
    variables: { id: deploymentId },
    fetchPolicy: 'network-only'
  })

  const {
    loading: mutationLoading,
    onSubmit,
    register,
    control
  } = useCreateLicenseForm(data?.deployment, onLicenseCreate, props.onClose)

  const loading = queryLoading || mutationLoading

  const { spacing, fontWeights, colors } = useTheme()
  return (
    <Dialog {...props} fullWidth maxWidth="sm">
      <DialogTitle>
        <Header style={{ marginTop: spacing(2) }}>Generate New License</Header>
      </DialogTitle>

      <DialogContent>
        <Form onSubmit={onSubmit}>
          <Form.Field>
            <Controller
              control={control}
              name="trial"
              render={({ field: { value, onChange } }) => (
                <Flex alignItems="center">
                  <Checkbox
                    checked={value}
                    onChange={() => onChange(!value)}
                    label="Trial"
                    style={{ fontWeight: fontWeights.bold }}
                  />
                  <p
                    style={{
                      paddingLeft: spacing(4),
                      color: colors.gray[500]
                    }}
                  >
                    Is the customer on a trial?
                  </p>
                </Flex>
              )}
            />
          </Form.Field>
          <Form.Field>
            <Grid>
              <Grid.Row columns={3}>
                <Grid.Column>
                  <Form.Field>
                    <label>Seats</label>
                    <input
                      type="number"
                      {...register('maxUsers', { valueAsNumber: true })}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <label>View Only Seats</label>
                    <input
                      type="number"
                      {...register('maxViewOnlyUsers', { valueAsNumber: true })}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <label>Teams</label>
                    <input
                      type="number"
                      {...register('maxTeams', { valueAsNumber: true })}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <label>Registered Models</label>
                    <Controller
                      control={control}
                      name="maxRegisteredModels"
                      render={({ field: { value, onChange } }) => (
                        <Select
                          style={{ minWidth: spacing(40), height: spacing(11) }}
                          value={value}
                          onChange={e => onChange(e.target.value)}
                        >
                          <MenuItem value={2}>Default - 2</MenuItem>
                          <MenuItem value={5}>Trial - 5</MenuItem>
                          <MenuItem value={1_000_000}>
                            Unlimited - 1,000,000
                          </MenuItem>
                        </Select>
                      )}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <label>Storage (GB)</label>
                    <input
                      {...register('maxStorageGb', { valueAsNumber: true })}
                      type="number"
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form.Field>

          <Form.Field>
            <label>Expiration Date</label>
            <input {...register('expiresAt')} type="date" />
          </Form.Field>

          <Form.Field>
            <label>Flags</label>

            <Controller
              control={control}
              name="flags"
              render={({ field: { value, onChange } }) => (
                <LicenseFlagsInput value={value} onChange={onChange} />
              )}
            />
          </Form.Field>

          <DialogActions>
            <Button
              color="green"
              loading={loading}
              content="Create New License"
              icon="key"
            />
          </DialogActions>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
