import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'
import { useTheme } from '@wandb/ui'
import { Card, CardContent, CardHeader } from 'common/Card'
import { Flex, Spacer } from 'common/Flex'
import { useDisclosure } from 'common/hooks/useDisclosure'
import { useViewer } from 'common/hooks/useViewer'
import { DeploymentDialogLicense } from 'common/license/DeploymentDialogLicense'
import { useCopyLicenseToClipboard } from 'common/license/useLicenseCopy'
import { RedirectDialog } from 'common/RedirectDialog'
import { Section } from 'common/Section'
import { readableFileSize } from 'common/utils/human-readable'
import { formatDistanceToNow, parseISO } from 'date-fns'
import { DeploymentQuery } from 'generated/deploy'
import first from 'lodash/first'
import { Button } from 'semantic-ui-react'

const gbToBytes = (gb?: number) => (gb ?? 0) * 1000 * 1000 * 1000

const LicenseInfo: React.FC<{
  name: string
  value?: React.ReactNode | number
}> = ({ name, value }) => {
  const { spacing, fontWeights, colors } = useTheme()
  return (
    <Flex
      alignItems="center"
      style={{
        margin: spacing(0.5, 0)
      }}
    >
      <span
        style={{
          width: spacing(45),
          fontWeight: fontWeights.bold,
          color: colors.grey[600]
        }}
      >
        {name}
      </span>
      <span>{value}</span>
    </Flex>
  )
}

export const DeploymentCardLicense: React.FC<{
  deployment: DeploymentQuery['deployment']
}> = ({ deployment }) => {
  const { spacing } = useTheme()

  const { isViewerAdmin } = useViewer()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const firstLicense = first(deployment?.licenses?.nodes)
  const { icon, copyLicense } = useCopyLicenseToClipboard(firstLicense)

  const maxRegisteredModelsValue =
    firstLicense == null
      ? 'None'
      : firstLicense.maxRegisteredModels >= 1000000
      ? 'Unlimited'
      : firstLicense.maxRegisteredModels

  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal
  } = useDisclosure()

  return (
    <Section>
      <Card>
        <CardContent>
          <CardHeader>
            <Flex alignItems="center">
              <span>License</span>
              <Spacer />
              <RedirectDialog open={isOpenModal} onClose={onCloseModal} />
              <Button
                onClick={() => {
                  copyLicense()
                  onOpenModal()
                }}
                size="tiny"
                color="green"
                icon={icon}
                style={{ padding: spacing(2, 3) }}
                content="Copy"
              />
              {isViewerAdmin && (
                <Button
                  onClick={onOpen}
                  basic
                  size="tiny"
                  color="green"
                  style={{ padding: spacing(2, 3) }}
                >
                  Edit
                </Button>
              )}
              {deployment && (
                <DeploymentDialogLicense
                  deploymentId={deployment?.id}
                  open={isOpen}
                  onClose={onClose}
                />
              )}
            </Flex>
          </CardHeader>

          <LicenseInfo name="Max Users" value={firstLicense?.maxUsers} />
          <LicenseInfo
            name="Max View Only Users"
            value={firstLicense?.maxViewOnlyUsers}
          />
          <LicenseInfo name="Max Teams" value={firstLicense?.maxTeams} />
          <LicenseInfo
            name="Max Registered Models"
            value={maxRegisteredModelsValue}
          />
          <LicenseInfo
            name="Max Storage"
            value={readableFileSize(
              gbToBytes(firstLicense?.maxStorageGb),
              true,
              1
            )}
          />
          {firstLicense?.trial && <LicenseInfo name="Trial" value="Yes" />}
          {firstLicense?.expiresAt && (
            <LicenseInfo
              name="Expires In"
              value={formatDistanceToNow(parseISO(firstLicense.expiresAt))}
            />
          )}
          <LicenseInfo
            name="Flags"
            value={firstLicense?.flags.map(f => (
              <Tooltip key={f.flag} title={f.description ?? ''}>
                <Chip
                  label={f.name}
                  size="small"
                  style={{ margin: spacing(0, 1, 0, 0) }}
                />
              </Tooltip>
            ))}
          />
        </CardContent>
      </Card>
    </Section>
  )
}
