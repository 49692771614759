/* eslint-disable jsx-a11y/label-has-associated-control */
import Box from '@mui/material/Box'
import { Card } from 'common/Card'
import { useMemo, useState } from 'react'
import { Controller, useForm, UseFormRegister } from 'react-hook-form'
import { Checkbox, Dropdown, Form } from 'semantic-ui-react'

import { TerraformInputSso } from './TerraformInputOidc'
import {
  ArrayToObject,
  findValue,
  ObjectToArray,
  TerraformConfigFormFC,
  TerraformConfigFormFCProps,
  variable
} from './utils'

/**
 * The options here should match the options for the `region` variable in
 * azure/single-teant/variables.tf
 *
 * @link https://github.com/claranet/terraform-azurerm-regions/blob/master/REGIONS.md
 */
const azureRegionOptions = Object.entries({
  'East US': 'eastus',
  'Central US': 'centralus',
  'West US': 'westus',
  'West Europe': 'westeurope',
  'Japan East': 'japaneast',
  'Korea Central': 'koreacentral'
}).map(([text, value]) => ({ value, text: `${text} [${value}]` }))

type TerraformAureFormState = {
  subdomain: string
  region: string
  wandbImage: string
  oidcIssuer: string
  oidcClientId: string
  oidcAuthMethod: string
  blobContainer: string
  storageAccount: string
  blobContainerAccessKey: string
  enableStorageVaultKey: boolean
  enableDatabaseVaultKey: boolean
}

const variablesObjectToArray: ObjectToArray<TerraformAureFormState> = obj => {
  return [
    variable('subdomain', obj.subdomain),
    variable('region', obj.region),

    variable('oidc_issuer', obj.oidcIssuer),
    variable('oidc_client_id', obj.oidcClientId),
    variable('oidc_auth_method', obj.oidcAuthMethod),

    variable('blob_container', obj.blobContainer),
    variable('storage_account', obj.storageAccount),

    variable('enable_storage_vault_key', obj.enableStorageVaultKey),
    variable('enable_database_vault_key', obj.enableDatabaseVaultKey)
  ]
}

const variablesArrayToObject: ArrayToObject<
  TerraformAureFormState
> = variables => {
  const find = (v: string) => findValue(variables, v)

  return {
    subdomain: find('subdomain') ?? '',
    region: find('region') ?? '',
    wandbImage: find('wandb_image') ?? 'wandb/local',

    oidcAuthMethod: find('oidc_auth_method') ?? '',
    oidcClientId: find('oidc_client_id') ?? '',
    oidcIssuer: find('oidc_issuer') ?? '',

    blobContainer: find('blob_container') ?? '',
    storageAccount: find('storage_account') ?? '',
    blobContainerAccessKey: find('blob_container_access_key') ?? '',

    enableStorageVaultKey: true,
    enableDatabaseVaultKey: true
  }
}

const useAzureForm = (props: TerraformConfigFormFCProps) => {
  const { variables, onSubmit } = props
  const defaultValues = useMemo(
    () => variablesArrayToObject(variables ?? []),
    [variables]
  )
  const form = useForm<TerraformAureFormState>({ defaultValues })

  const onSubmitForm = form.handleSubmit(async data => {
    const arr = variablesObjectToArray(data)
    await onSubmit(arr)
  })
  return { ...form, onSubmitForm, defaultValues }
}

type TerraformInputByobProps = {
  register: UseFormRegister<{
    blobContainer: string
    storageAccount: string
    blobContainerAccessKey: string
  }>
}

const TerraformInputAzureByob: React.FC<TerraformInputByobProps> = props => {
  const { register } = props
  const [show, setShow] = useState(false)
  const toggle = () => setShow(s => !s)

  const blobContainer = register('blobContainer')
  const blobContainerAccessKey = register('blobContainerAccessKey')
  const storageAccount = register('storageAccount')

  return (
    <Form.Field>
      <Checkbox
        toggle
        onClick={toggle}
        checked={show}
        label="Enable bring your own blob (BYOB)"
      />

      {show && (
        <Box marginTop={2}>
          <Card>
            <Box margin={6}>
              <Form.Field required>
                <label>Blob Container</label>
                <input {...blobContainer} />
              </Form.Field>
              <Form.Field required>
                <label>Storage Account</label>
                <input {...storageAccount} />
              </Form.Field>
              <Form.Field required>
                <label>Storage Access Key</label>
                <input type="password" {...blobContainerAccessKey} />
              </Form.Field>
            </Box>
          </Card>
        </Box>
      )}
    </Form.Field>
  )
}

export const TerraformConfigAzure: TerraformConfigFormFC = props => {
  const { control, register, onSubmitForm } = useAzureForm(props)

  const { variables } = props
  const alreadyHasRegion = useMemo(
    () => findValue(variables ?? [], 'region') != null,
    [variables]
  )
  const alreadyHasSubdomain = useMemo(
    () => findValue(variables ?? [], 'subdomain') != null,
    [variables]
  )

  return (
    <Form onSubmit={onSubmitForm}>
      <Form.Group widths="equal">
        <Form.Field required disabled={alreadyHasRegion}>
          <label>Region</label>
          <Controller
            control={control}
            name="region"
            render={({ field: { value, onChange } }) => (
              <Dropdown
                fluid
                search
                selection
                upward
                autoComplete="off"
                options={azureRegionOptions}
                value={value}
                onChange={(_, { value }) => onChange(value)}
                placeholder="Select Region"
              />
            )}
          />
        </Form.Field>
        <Form.Field required disabled={alreadyHasSubdomain}>
          <label>Subdomain</label>
          <input {...register('subdomain')} />
        </Form.Field>
      </Form.Group>

      <TerraformInputAzureByob register={register as any} />
      <TerraformInputSso register={register as any} />

      {props.footer}
    </Form>
  )
}
