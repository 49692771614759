/**
  * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
  */
/* eslint-disable */

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {"context":{"service":"deploy"}} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `BigInt` scalar type represents non-fractional signed whole numeric values.
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/BigInt
   */
  BigInt: any;
  /** The `Byte` scalar type represents byte value as a Buffer */
  Bytes: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** An arbitrary-precision Decimal type */
  Decimal: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  Json: any;
};

export type BatchPayload = {
  __typename?: 'BatchPayload';
  count: Scalars['Int'];
};

export type BooleanFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<Scalars['Boolean']>;
};

export type Channel = {
  __typename?: 'Channel';
  deployments: Array<Deployment>;
  id: Scalars['ID'];
  inheritsFrom?: Maybe<Channel>;
  inheritsFromId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  policy?: Maybe<Scalars['String']>;
  releaseDistribution: Array<ReleaseDistribution>;
  releases: Array<ChannelRelease>;
  type?: Maybe<ChannelType>;
};

export type ChannelCreateInput = {
  inheritsFromId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type ChannelRelease = {
  __typename?: 'ChannelRelease';
  channel?: Maybe<Channel>;
  channelId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdById?: Maybe<Scalars['String']>;
  extendsId?: Maybe<Scalars['ID']>;
  finalState: Scalars['Json'];
  id: Scalars['ID'];
  name: Scalars['String'];
  parentState?: Maybe<Scalars['Json']>;
  patch: Scalars['Boolean'];
  state: Scalars['Json'];
};

export type ChannelReleaseCreateInput = {
  channelId: Scalars['String'];
  name: Scalars['String'];
  patch?: InputMaybe<Scalars['Boolean']>;
  policy?: InputMaybe<Scalars['String']>;
  state: Scalars['Json'];
};

export enum ChannelType {
  Nightly = 'NIGHTLY',
  Stable = 'STABLE'
}

export type ChannelUpdateInput = {
  inheritsFromId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  policy?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ChannelType>;
};

export type ConventionalCommit = {
  __typename?: 'ConventionalCommit';
  breaking?: Maybe<Scalars['Boolean']>;
  scope?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<Scalars['DateTime']>;
};

export type Deployment = {
  __typename?: 'Deployment';
  channelId?: Maybe<Scalars['ID']>;
  contractExpiresAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  /** ID of the user who created the deployment. */
  creatorId: Scalars['ID'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastChannelPingedAt?: Maybe<Scalars['DateTime']>;
  lastChannelRelease?: Maybe<ChannelRelease>;
  lastChannelReleaseId?: Maybe<Scalars['ID']>;
  licenseOrder?: Maybe<LicenseOrder>;
  licenses?: Maybe<LicenseConnection>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  pocExpiresAt?: Maybe<Scalars['DateTime']>;
  releases?: Maybe<Array<Release>>;
  sfdcAccountId: Scalars['String'];
  sfdcOpportunityId: Scalars['String'];
  state?: Maybe<DeploymentState>;
  terraformWorkspaceId?: Maybe<Scalars['String']>;
  testing: Scalars['Boolean'];
  type: DeploymentType;
  usage: Array<DeploymentUsageSnapshot>;
  variables?: Maybe<Array<DeploymentVariable>>;
  workspace?: Maybe<DeploymentWorkspace>;
};


export type DeploymentLicensesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DeploymentCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  /** License to configure the deployment with. */
  license?: InputMaybe<DeploymentLicenseCreateInput>;
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  type: DeploymentType;
  variables?: InputMaybe<Array<DeploymentVariableInput>>;
  workspace?: InputMaybe<DeploymentWorkspaceInput>;
};

export type DeploymentDevelopmentCreateInput = {
  branch?: InputMaybe<Scalars['String']>;
  type: DeploymentType;
};

export type DeploymentEdge = {
  __typename?: 'DeploymentEdge';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
  cursor: Scalars['String'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
  node: Deployment;
};

export type DeploymentLicenseCreateInput = {
  expiresAt: Scalars['DateTime'];
  flags: Array<LicenseFlagValue>;
  maxRegisteredModels: Scalars['Int'];
  maxStorageGb: Scalars['Int'];
  maxTeams: Scalars['Int'];
  maxUsers: Scalars['Int'];
  maxViewOnlyUsers: Scalars['Int'];
  trial: Scalars['Boolean'];
};

export type DeploymentOrderCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  /** License to configure the deployment with. */
  license?: InputMaybe<DeploymentLicenseCreateInput>;
  licenseOrderId: Scalars['String'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  type: DeploymentType;
  variables?: InputMaybe<Array<DeploymentVariableInput>>;
  workspace?: InputMaybe<DeploymentWorkspaceInput>;
};

export type DeploymentOutput = {
  __typename?: 'DeploymentOutput';
  name: Scalars['String'];
  sensitive: Scalars['Boolean'];
  type: Scalars['String'];
  value: Scalars['String'];
};

export type DeploymentResource = {
  __typename?: 'DeploymentResource';
  count: Scalars['Int'];
  module: Scalars['String'];
  name: Scalars['String'];
  provider: Scalars['String'];
  type: Scalars['String'];
};

export type DeploymentState = {
  __typename?: 'DeploymentState';
  id: Scalars['ID'];
  outputs?: Maybe<Array<DeploymentOutput>>;
  resources?: Maybe<Array<DeploymentResource>>;
};

export enum DeploymentType {
  Aws = 'AWS',
  Azure = 'AZURE',
  Gcp = 'GCP',
  Manual = 'MANUAL',
  TerraformTest = 'TERRAFORM_TEST'
}

export type DeploymentUpdateInput = {
  channelId?: InputMaybe<Scalars['ID']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  variables?: InputMaybe<Array<DeploymentVariableInput>>;
};

export type DeploymentUsageSnapshot = {
  __typename?: 'DeploymentUsageSnapshot';
  activeUsers: Scalars['Int'];
  artifacts: Scalars['Int'];
  date: Scalars['DateTime'];
  lastReportedAt: Scalars['DateTime'];
  projects: Scalars['Int'];
  reports: Scalars['Int'];
  runs: Scalars['Int'];
  sweeps: Scalars['Int'];
  teams: Scalars['Int'];
  users: Scalars['Int'];
  version: Scalars['String'];
};

export type DeploymentVariable = {
  __typename?: 'DeploymentVariable';
  category: Scalars['String'];
  hcl?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  sensitive: Scalars['Boolean'];
  value?: Maybe<Scalars['String']>;
};

export type DeploymentVariableInput = {
  category: Scalars['String'];
  hcl?: InputMaybe<Scalars['Boolean']>;
  key: Scalars['String'];
  sensitive?: InputMaybe<Scalars['Boolean']>;
  value: Scalars['String'];
};

export type DeploymentWhereInput = {
  AND?: InputMaybe<Array<DeploymentWhereInput>>;
  OR?: InputMaybe<Array<DeploymentWhereInput>>;
  channelId?: InputMaybe<StringFilter>;
  lastChannelReleaseId?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<StringFilter>;
  terraformWorkspaceId?: InputMaybe<StringFilter>;
  testing?: InputMaybe<BooleanFilter>;
  type?: InputMaybe<StringFilter>;
};

export type DeploymentWorkspace = {
  __typename?: 'DeploymentWorkspace';
  allowDestroyPlan: Scalars['Boolean'];
  executionMode: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  versionControl?: Maybe<DeploymentWorkspaceVersionControl>;
};

export type DeploymentWorkspaceInput = {
  agentPoolId?: InputMaybe<Scalars['String']>;
  allowDestroyPlan?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  versionControl?: InputMaybe<DeploymentWorkspaceVersionControlInput>;
  workingDirectory?: InputMaybe<Scalars['String']>;
};

export type DeploymentWorkspaceVersionControl = {
  __typename?: 'DeploymentWorkspaceVersionControl';
  branch: Scalars['String'];
  identifier: Scalars['String'];
};

/** Settings for the workspace's VCS repository. */
export type DeploymentWorkspaceVersionControlInput = {
  /** The repository branch that code will execute from. */
  branch?: InputMaybe<Scalars['String']>;
  /** A reference to your VCS repository in the format :org/:repo where :org and :repo refer to the organization and repository in your VCS provider. */
  identifier?: InputMaybe<Scalars['String']>;
  /** The version control connection (OAuth Connection + Token) to use. */
  oauthTokenId?: InputMaybe<Scalars['String']>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type ImageVersion = {
  __typename?: 'ImageVersion';
  id: Scalars['ID'];
  image: Scalars['String'];
  lastUpdated: Scalars['DateTime'];
  name: Scalars['String'];
};

export type ImageVersionPage = {
  __typename?: 'ImageVersionPage';
  count: Scalars['Int'];
  results: Array<ImageVersion>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type License = {
  __typename?: 'License';
  createdAt: Scalars['DateTime'];
  deploymentId: Scalars['ID'];
  expiresAt: Scalars['DateTime'];
  flags: Array<LicenseFlag>;
  id: Scalars['ID'];
  license: Scalars['String'];
  maxRegisteredModels: Scalars['Int'];
  maxStorageGb: Scalars['Int'];
  maxTeams: Scalars['Int'];
  maxUsers: Scalars['Int'];
  maxViewOnlyUsers: Scalars['Int'];
  trial: Scalars['Boolean'];
};

export type LicenseConnection = {
  __typename?: 'LicenseConnection';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
  edges: Array<LicenseEdge>;
  /** Flattened list of License type */
  nodes: Array<License>;
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
  pageInfo: PageInfo;
};

/** Input for creating a license */
export type LicenseCreateInput = {
  deploymentId: Scalars['ID'];
  expiresAt: Scalars['DateTime'];
  flags: Array<LicenseFlagValue>;
  maxRegisteredModels: Scalars['Int'];
  maxStorageGb: Scalars['Int'];
  maxTeams: Scalars['Int'];
  maxUsers: Scalars['Int'];
  maxViewOnlyUsers: Scalars['Int'];
  trial: Scalars['Boolean'];
};

export type LicenseEdge = {
  __typename?: 'LicenseEdge';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
  cursor: Scalars['String'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
  node: License;
};

export type LicenseFlag = {
  __typename?: 'LicenseFlag';
  description: Scalars['String'];
  flag: LicenseFlagValue;
  name: Scalars['String'];
};

export enum LicenseFlagValue {
  Byob = 'BYOB',
  EnforceLimits = 'ENFORCE_LIMITS',
  LaunchClusters = 'LAUNCH_CLUSTERS',
  Management = 'MANAGEMENT',
  Notifications = 'NOTIFICATIONS',
  Scalable = 'SCALABLE'
}

export type LicenseOrder = {
  __typename?: 'LicenseOrder';
  claimedByUserId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  createdByUserId: Scalars['ID'];
  customerEmail: Scalars['String'];
  /** License order was used by this deployment. */
  deployment?: Maybe<Deployment>;
  deploymentId?: Maybe<Scalars['ID']>;
  expiresAt: Scalars['DateTime'];
  flags: Array<LicenseFlag>;
  id: Scalars['ID'];
  maxRegisteredModels: Scalars['Int'];
  maxStorageGb: Scalars['Int'];
  maxTeams: Scalars['Int'];
  maxUsers: Scalars['Int'];
  maxViewOnlyUsers: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
  trial: Scalars['Boolean'];
};

export type LicenseOrderConnection = {
  __typename?: 'LicenseOrderConnection';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
  edges: Array<LicenseOrderEdge>;
  /** Flattened list of LicenseOrder type */
  nodes: Array<LicenseOrder>;
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
  pageInfo: PageInfo;
};

export type LicenseOrderCreateInput = {
  contractExpiresAt?: InputMaybe<Scalars['DateTime']>;
  customerEmail: Scalars['String'];
  expiresAt: Scalars['DateTime'];
  flags?: InputMaybe<Array<LicenseFlagValue>>;
  maxRegisteredModels: Scalars['Int'];
  maxStorageGb: Scalars['Int'];
  maxTeams: Scalars['Int'];
  maxUsers: Scalars['Int'];
  maxViewOnlyUsers: Scalars['Int'];
  notes?: InputMaybe<Scalars['String']>;
  pocExpiresAt?: InputMaybe<Scalars['DateTime']>;
  sfdcAccountId: Scalars['String'];
  sfdcOpportunityId: Scalars['String'];
  trial: Scalars['Boolean'];
};

export type LicenseOrderEdge = {
  __typename?: 'LicenseOrderEdge';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
  cursor: Scalars['String'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
  node: LicenseOrder;
};

export type LicenseOrderWhereInput = {
  AND?: InputMaybe<Array<LicenseOrderWhereInput>>;
  OR?: InputMaybe<Array<LicenseOrderWhereInput>>;
  customerEmail?: InputMaybe<StringFilter>;
  deploymentId?: InputMaybe<StringFilter>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Create a new channel. */
  createChannel?: Maybe<Channel>;
  /** Create a new channel release. */
  createChannelRelease?: Maybe<BatchPayload>;
  /** Create a W&B deployment. */
  createDeployment?: Maybe<Deployment>;
  /** Create a W&B developer deployment. */
  createDeploymentDevelopment?: Maybe<Deployment>;
  /** Create a W&B deployment with an order ID. */
  createDeploymentFromOrder?: Maybe<Deployment>;
  /** Create a W&B deployment trial to unlock extra features. */
  createDeploymentTrial?: Maybe<Deployment>;
  /** Create a license (only admins can use this mutation) */
  createLicense?: Maybe<License>;
  createLicenseOrder?: Maybe<LicenseOrder>;
  /** Create a new release. */
  createRelease?: Maybe<Release>;
  /** Delete a channel. */
  deleteChannel?: Maybe<Channel>;
  deleteDeployment?: Maybe<Deployment>;
  deleteLicenseOrder?: Maybe<LicenseOrder>;
  /** Update a channel. */
  updateChannel?: Maybe<Channel>;
  /** Update a W&B deployment */
  updateDeployment?: Maybe<Deployment>;
};


export type MutationCreateChannelArgs = {
  data: ChannelCreateInput;
};


export type MutationCreateChannelReleaseArgs = {
  data: ChannelReleaseCreateInput;
};


export type MutationCreateDeploymentArgs = {
  data: DeploymentCreateInput;
};


export type MutationCreateDeploymentDevelopmentArgs = {
  data: DeploymentDevelopmentCreateInput;
};


export type MutationCreateDeploymentFromOrderArgs = {
  data: DeploymentOrderCreateInput;
};


export type MutationCreateDeploymentTrialArgs = {
  data: DeploymentCreateInput;
};


export type MutationCreateLicenseArgs = {
  data: LicenseCreateInput;
};


export type MutationCreateLicenseOrderArgs = {
  data: LicenseOrderCreateInput;
};


export type MutationCreateReleaseArgs = {
  input: ReleaseCreateInput;
};


export type MutationDeleteChannelArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDeploymentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLicenseOrderArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateChannelArgs = {
  data: ChannelUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateDeploymentArgs = {
  data: DeploymentUpdateInput;
  id: Scalars['ID'];
};

export type NextLocalReleaseInfo = {
  __typename?: 'NextLocalReleaseInfo';
  cc: Array<ConventionalCommit>;
  version?: Maybe<Scalars['String']>;
};

/** PageInfo cursor, as defined in https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** The cursor corresponding to the last nodes in edges. Null if the connection is empty. */
  endCursor?: Maybe<Scalars['String']>;
  /** Used to indicate whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Used to indicate whether more edges exist prior to the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** The cursor corresponding to the first nodes in edges. Null if the connection is empty. */
  startCursor?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  channel?: Maybe<Channel>;
  channels: Array<Channel>;
  /** Find a deployment by ID */
  deployment?: Maybe<Deployment>;
  deployments?: Maybe<QueryDeployments_Connection>;
  imageVersions?: Maybe<ImageVersionPage>;
  /** Lookup a license by ID. */
  license?: Maybe<License>;
  /** Returns a list of all available license flags. */
  licenseFlags: Array<LicenseFlag>;
  licenseOrder?: Maybe<LicenseOrder>;
  licenseOrders?: Maybe<LicenseOrderConnection>;
  nextLocalRelease?: Maybe<NextLocalReleaseInfo>;
  release?: Maybe<Release>;
};


export type QueryChannelArgs = {
  id: Scalars['ID'];
};


export type QueryDeploymentArgs = {
  id: Scalars['ID'];
};


export type QueryDeploymentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeploymentWhereInput>;
};


export type QueryLicenseArgs = {
  id: Scalars['ID'];
};


export type QueryLicenseOrderArgs = {
  id: Scalars['ID'];
};


export type QueryLicenseOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LicenseOrderWhereInput>;
};


export type QueryReleaseArgs = {
  id: Scalars['ID'];
};

export type QueryDeployments_Connection = {
  __typename?: 'QueryDeployments_Connection';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
  edges: Array<DeploymentEdge>;
  /** Flattened list of Deployment type */
  nodes: Array<Deployment>;
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type Release = {
  __typename?: 'Release';
  apply?: Maybe<ReleaseApply>;
  applyId?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  events: Array<ReleaseEvent>;
  id: Scalars['ID'];
  isDestroy: Scalars['Boolean'];
  message: Scalars['String'];
  plan?: Maybe<ReleasePlan>;
  planId?: Maybe<Scalars['String']>;
  refresh: Scalars['Boolean'];
  status: ReleaseStatus;
  statusTimestamps?: Maybe<ReleaseTimestamps>;
};

export type ReleaseApply = {
  __typename?: 'ReleaseApply';
  hasChanges?: Maybe<Scalars['Boolean']>;
  logReadUrl?: Maybe<Scalars['String']>;
  logs?: Maybe<Array<Maybe<Scalars['Json']>>>;
  resourceAdditions?: Maybe<Scalars['Int']>;
  resourceChanges?: Maybe<Scalars['Int']>;
  resourceDestructions?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
};

export type ReleaseCreateInput = {
  deploymentId: Scalars['ID'];
  destroy?: InputMaybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ReleaseDistribution = {
  __typename?: 'ReleaseDistribution';
  count: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  percentage: Scalars['Float'];
};

export type ReleaseEvent = {
  __typename?: 'ReleaseEvent';
  action: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
};

export type ReleasePlan = {
  __typename?: 'ReleasePlan';
  hasChanges?: Maybe<Scalars['Boolean']>;
  logReadUrl?: Maybe<Scalars['String']>;
  logs?: Maybe<Array<Maybe<Scalars['Json']>>>;
  resourceAdditions?: Maybe<Scalars['Int']>;
  resourceChanges?: Maybe<Scalars['Int']>;
  resourceDestructions?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
};

export enum ReleaseStatus {
  Applied = 'Applied',
  ApplyQueued = 'ApplyQueued',
  Applying = 'Applying',
  Canceled = 'Canceled',
  Confirmed = 'Confirmed',
  CostEstimated = 'CostEstimated',
  CostEstimating = 'CostEstimating',
  Discarded = 'Discarded',
  Errored = 'Errored',
  ForceCanceled = 'ForceCanceled',
  Pending = 'Pending',
  PlanQueued = 'PlanQueued',
  Planned = 'Planned',
  PlannedAndFinished = 'PlannedAndFinished',
  Planning = 'Planning',
  PolicyChecked = 'PolicyChecked',
  PolicyChecking = 'PolicyChecking',
  PolicyOverride = 'PolicyOverride',
  PolicySoftFailed = 'PolicySoftFailed'
}

export type ReleaseTimestamps = {
  __typename?: 'ReleaseTimestamps';
  appliedAt?: Maybe<Scalars['DateTime']>;
  applyQueuedAt?: Maybe<Scalars['DateTime']>;
  applyingAt?: Maybe<Scalars['DateTime']>;
  confirmedAt?: Maybe<Scalars['DateTime']>;
  erroredAt?: Maybe<Scalars['DateTime']>;
  planQueueableAt?: Maybe<Scalars['DateTime']>;
  planQueuedAt?: Maybe<Scalars['DateTime']>;
  plannedAt?: Maybe<Scalars['DateTime']>;
  planningAt?: Maybe<Scalars['DateTime']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<Scalars['String']>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type CreateReleaseMutationVariables = Exact<{
  input: ReleaseCreateInput;
}>;


export type CreateReleaseMutation = { __typename?: 'Mutation', createRelease?: { __typename?: 'Release', id: string } | null };

export type CreateDeploymentMutationVariables = Exact<{
  data: DeploymentCreateInput;
}>;


export type CreateDeploymentMutation = { __typename?: 'Mutation', createDeployment?: { __typename?: 'Deployment', id: string } | null };

export type CreateDeploymentFromOrderMutationVariables = Exact<{
  data: DeploymentOrderCreateInput;
}>;


export type CreateDeploymentFromOrderMutation = { __typename?: 'Mutation', createDeploymentFromOrder?: { __typename?: 'Deployment', id: string } | null };

export type CreateDeploymentTrialMutationVariables = Exact<{
  data: DeploymentCreateInput;
}>;


export type CreateDeploymentTrialMutation = { __typename?: 'Mutation', createDeploymentTrial?: { __typename?: 'Deployment', id: string } | null };

export type DeleteDeploymentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDeploymentMutation = { __typename?: 'Mutation', deployment?: { __typename?: 'Deployment', id: string } | null };

export type UpdateDeploymentMutationVariables = Exact<{
  id: Scalars['ID'];
  data: DeploymentUpdateInput;
}>;


export type UpdateDeploymentMutation = { __typename?: 'Mutation', updateDeployment?: { __typename?: 'Deployment', id: string, name: string, description?: string | null } | null };

export type CreateLicenseMutationVariables = Exact<{
  data: LicenseCreateInput;
}>;


export type CreateLicenseMutation = { __typename?: 'Mutation', createLicense?: { __typename?: 'License', id: string } | null };

export type CreateLicenseOrderMutationVariables = Exact<{
  data: LicenseOrderCreateInput;
}>;


export type CreateLicenseOrderMutation = { __typename?: 'Mutation', createLicenseOrder?: { __typename?: 'LicenseOrder', id: string } | null };

export type DeploymentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeploymentQuery = { __typename?: 'Query', deployment?: { __typename?: 'Deployment', id: string, type: DeploymentType, name: string, creatorId: string, organizationId: string, description?: string | null, terraformWorkspaceId?: string | null, sfdcAccountId: string, sfdcOpportunityId: string, pocExpiresAt?: any | null, contractExpiresAt?: any | null, createdAt: any, licenses?: { __typename?: 'LicenseConnection', nodes: Array<{ __typename?: 'License', id: string, license: string, trial: boolean, deploymentId: string, maxStorageGb: number, maxTeams: number, maxUsers: number, maxViewOnlyUsers: number, maxRegisteredModels: number, createdAt: any, expiresAt: any, flags: Array<{ __typename?: 'LicenseFlag', flag: LicenseFlagValue, name: string, description: string }> }> } | null, releases?: Array<{ __typename?: 'Release', id: string, message: string, createdAt?: any | null, canceledAt?: any | null, refresh: boolean, status: ReleaseStatus, statusTimestamps?: { __typename?: 'ReleaseTimestamps', appliedAt?: any | null, erroredAt?: any | null } | null }> | null } | null };

export type DeploymentLicenseQueryVariables = Exact<{
  deploymentId: Scalars['ID'];
}>;


export type DeploymentLicenseQuery = { __typename?: 'Query', deployment?: { __typename?: 'Deployment', id: string, licenses?: { __typename?: 'LicenseConnection', nodes: Array<{ __typename?: 'License', id: string, deploymentId: string, license: string }> } | null } | null };

export type DeploymentLicensesQueryVariables = Exact<{
  deploymentId: Scalars['ID'];
}>;


export type DeploymentLicensesQuery = { __typename?: 'Query', deployment?: { __typename?: 'Deployment', id: string, licenses?: { __typename?: 'LicenseConnection', nodes: Array<{ __typename?: 'License', id: string, maxUsers: number, maxViewOnlyUsers: number, maxStorageGb: number, maxTeams: number, maxRegisteredModels: number, trial: boolean, createdAt: any, expiresAt: any, deploymentId: string, license: string, flags: Array<{ __typename?: 'LicenseFlag', name: string, flag: LicenseFlagValue, description: string }> }> } | null } | null };

export type DeploymentStateQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeploymentStateQuery = { __typename?: 'Query', deployment?: { __typename?: 'Deployment', id: string, workspace?: { __typename?: 'DeploymentWorkspace', id: string, name: string, versionControl?: { __typename?: 'DeploymentWorkspaceVersionControl', branch: string, identifier: string } | null } | null, state?: { __typename?: 'DeploymentState', id: string, resources?: Array<{ __typename?: 'DeploymentResource', count: number, module: string, name: string, provider: string, type: string }> | null, outputs?: Array<{ __typename?: 'DeploymentOutput', name: string, sensitive: boolean, type: string, value: string }> | null } | null } | null };

export type DeploymentVariablesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeploymentVariablesQuery = { __typename?: 'Query', deployment?: { __typename?: 'Deployment', id: string, variables?: Array<{ __typename?: 'DeploymentVariable', id: string, key: string, value?: string | null, category: string, sensitive: boolean, hcl?: boolean | null }> | null } | null };

export type DeploymentsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeploymentWhereInput>;
  includeLicenseInfo?: InputMaybe<Scalars['Boolean']>;
  includeReleases?: InputMaybe<Scalars['Boolean']>;
  includeWorkspace?: InputMaybe<Scalars['Boolean']>;
}>;


export type DeploymentsQuery = { __typename?: 'Query', deployments?: { __typename?: 'QueryDeployments_Connection', nodes: Array<{ __typename?: 'Deployment', id: string, type: DeploymentType, name: string, terraformWorkspaceId?: string | null, description?: string | null, organizationId: string, createdAt: any, workspace?: { __typename?: 'DeploymentWorkspace', id: string, name: string, versionControl?: { __typename?: 'DeploymentWorkspaceVersionControl', branch: string, identifier: string } | null } | null, releases?: Array<{ __typename?: 'Release', id: string, createdAt?: any | null, message: string, status: ReleaseStatus }> | null, licenses?: { __typename?: 'LicenseConnection', nodes: Array<{ __typename?: 'License', id: string, license: string, trial: boolean, deploymentId: string, maxStorageGb: number, maxTeams: number, maxUsers: number, maxViewOnlyUsers: number, createdAt: any, expiresAt: any, flags: Array<{ __typename?: 'LicenseFlag', flag: LicenseFlagValue, name: string, description: string }> }> } | null }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } | null };

export type AllFlagsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllFlagsQuery = { __typename?: 'Query', flags: Array<{ __typename?: 'LicenseFlag', flag: LicenseFlagValue, description: string, name: string }> };

export type LicenseOrderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LicenseOrderQuery = { __typename?: 'Query', licenseOrder?: { __typename?: 'LicenseOrder', id: string, trial: boolean, deploymentId?: string | null, maxUsers: number, maxViewOnlyUsers: number, maxStorageGb: number, maxTeams: number, maxRegisteredModels: number, expiresAt: any, createdAt: any, flags: Array<{ __typename?: 'LicenseFlag', flag: LicenseFlagValue, name: string, description: string }> } | null };

export type DeleteLicenseOrderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteLicenseOrderMutation = { __typename?: 'Mutation', deleteLicenseOrder?: { __typename?: 'LicenseOrder', id: string } | null };

export type LicenseOrdersQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LicenseOrderWhereInput>;
}>;


export type LicenseOrdersQuery = { __typename?: 'Query', licenseOrders?: { __typename?: 'LicenseOrderConnection', nodes: Array<{ __typename?: 'LicenseOrder', id: string, notes?: string | null, maxStorageGb: number, maxUsers: number, maxViewOnlyUsers: number, maxTeams: number, customerEmail: string, claimedByUserId?: string | null, createdByUserId: string, expiresAt: any, createdAt: any, deployment?: { __typename?: 'Deployment', id: string, name: string, type: DeploymentType, createdAt: any } | null, flags: Array<{ __typename?: 'LicenseFlag', flag: LicenseFlagValue, name: string, description: string }> }> } | null };

export type NextLocalReleaseQueryVariables = Exact<{ [key: string]: never; }>;


export type NextLocalReleaseQuery = { __typename?: 'Query', nextLocalRelease?: { __typename?: 'NextLocalReleaseInfo', version?: string | null, cc: Array<{ __typename?: 'ConventionalCommit', type?: string | null, scope?: string | null, subject?: string | null, breaking?: boolean | null }> } | null };

export type ReleaseQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReleaseQuery = { __typename?: 'Query', release?: { __typename?: 'Release', id: string, message: string, createdAt?: any | null, canceledAt?: any | null, refresh: boolean, status: ReleaseStatus, isDestroy: boolean, statusTimestamps?: { __typename?: 'ReleaseTimestamps', erroredAt?: any | null, appliedAt?: any | null } | null, events: Array<{ __typename?: 'ReleaseEvent', target?: string | null, action: string, createdAt?: any | null }>, plan?: { __typename?: 'ReleasePlan', status: string, resourceAdditions?: number | null, resourceDestructions?: number | null, resourceChanges?: number | null, logs?: Array<any | null> | null } | null, apply?: { __typename?: 'ReleaseApply', status: string, resourceAdditions?: number | null, resourceDestructions?: number | null, resourceChanges?: number | null, logs?: Array<any | null> | null } | null } | null };

export type ReleaseLogsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReleaseLogsQuery = { __typename?: 'Query', release?: { __typename?: 'Release', id: string, plan?: { __typename?: 'ReleasePlan', logs?: Array<any | null> | null } | null, apply?: { __typename?: 'ReleaseApply', logs?: Array<any | null> | null } | null } | null };

export type ReleaseOutputsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReleaseOutputsQuery = { __typename?: 'Query', release?: { __typename?: 'Release', id: string, plan?: { __typename?: 'ReleasePlan', logs?: Array<any | null> | null } | null, apply?: { __typename?: 'ReleaseApply', logs?: Array<any | null> | null } | null } | null };

export type VersionsQueryVariables = Exact<{ [key: string]: never; }>;


export type VersionsQuery = { __typename?: 'Query', versions?: { __typename?: 'ImageVersionPage', count: number, results: Array<{ __typename?: 'ImageVersion', id: string, name: string, lastUpdated: any }> } | null };


export const CreateReleaseDocument = gql`
    mutation CreateRelease($input: ReleaseCreateInput!) {
  createRelease(input: $input) {
    id
  }
}
    `;
export type CreateReleaseMutationFn = Apollo.MutationFunction<CreateReleaseMutation, CreateReleaseMutationVariables>;

/**
 * __useCreateReleaseMutation__
 *
 * To run a mutation, you first call `useCreateReleaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReleaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReleaseMutation, { data, loading, error }] = useCreateReleaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReleaseMutation(baseOptions?: Apollo.MutationHookOptions<CreateReleaseMutation, CreateReleaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReleaseMutation, CreateReleaseMutationVariables>(CreateReleaseDocument, options);
      }
export type CreateReleaseMutationHookResult = ReturnType<typeof useCreateReleaseMutation>;
export type CreateReleaseMutationResult = Apollo.MutationResult<CreateReleaseMutation>;
export type CreateReleaseMutationOptions = Apollo.BaseMutationOptions<CreateReleaseMutation, CreateReleaseMutationVariables>;
export const CreateDeploymentDocument = gql`
    mutation CreateDeployment($data: DeploymentCreateInput!) {
  createDeployment(data: $data) {
    id
  }
}
    `;
export type CreateDeploymentMutationFn = Apollo.MutationFunction<CreateDeploymentMutation, CreateDeploymentMutationVariables>;

/**
 * __useCreateDeploymentMutation__
 *
 * To run a mutation, you first call `useCreateDeploymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeploymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeploymentMutation, { data, loading, error }] = useCreateDeploymentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDeploymentMutation(baseOptions?: Apollo.MutationHookOptions<CreateDeploymentMutation, CreateDeploymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDeploymentMutation, CreateDeploymentMutationVariables>(CreateDeploymentDocument, options);
      }
export type CreateDeploymentMutationHookResult = ReturnType<typeof useCreateDeploymentMutation>;
export type CreateDeploymentMutationResult = Apollo.MutationResult<CreateDeploymentMutation>;
export type CreateDeploymentMutationOptions = Apollo.BaseMutationOptions<CreateDeploymentMutation, CreateDeploymentMutationVariables>;
export const CreateDeploymentFromOrderDocument = gql`
    mutation CreateDeploymentFromOrder($data: DeploymentOrderCreateInput!) {
  createDeploymentFromOrder(data: $data) {
    id
  }
}
    `;
export type CreateDeploymentFromOrderMutationFn = Apollo.MutationFunction<CreateDeploymentFromOrderMutation, CreateDeploymentFromOrderMutationVariables>;

/**
 * __useCreateDeploymentFromOrderMutation__
 *
 * To run a mutation, you first call `useCreateDeploymentFromOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeploymentFromOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeploymentFromOrderMutation, { data, loading, error }] = useCreateDeploymentFromOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDeploymentFromOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateDeploymentFromOrderMutation, CreateDeploymentFromOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDeploymentFromOrderMutation, CreateDeploymentFromOrderMutationVariables>(CreateDeploymentFromOrderDocument, options);
      }
export type CreateDeploymentFromOrderMutationHookResult = ReturnType<typeof useCreateDeploymentFromOrderMutation>;
export type CreateDeploymentFromOrderMutationResult = Apollo.MutationResult<CreateDeploymentFromOrderMutation>;
export type CreateDeploymentFromOrderMutationOptions = Apollo.BaseMutationOptions<CreateDeploymentFromOrderMutation, CreateDeploymentFromOrderMutationVariables>;
export const CreateDeploymentTrialDocument = gql`
    mutation CreateDeploymentTrial($data: DeploymentCreateInput!) {
  createDeploymentTrial(data: $data) {
    id
  }
}
    `;
export type CreateDeploymentTrialMutationFn = Apollo.MutationFunction<CreateDeploymentTrialMutation, CreateDeploymentTrialMutationVariables>;

/**
 * __useCreateDeploymentTrialMutation__
 *
 * To run a mutation, you first call `useCreateDeploymentTrialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeploymentTrialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeploymentTrialMutation, { data, loading, error }] = useCreateDeploymentTrialMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDeploymentTrialMutation(baseOptions?: Apollo.MutationHookOptions<CreateDeploymentTrialMutation, CreateDeploymentTrialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDeploymentTrialMutation, CreateDeploymentTrialMutationVariables>(CreateDeploymentTrialDocument, options);
      }
export type CreateDeploymentTrialMutationHookResult = ReturnType<typeof useCreateDeploymentTrialMutation>;
export type CreateDeploymentTrialMutationResult = Apollo.MutationResult<CreateDeploymentTrialMutation>;
export type CreateDeploymentTrialMutationOptions = Apollo.BaseMutationOptions<CreateDeploymentTrialMutation, CreateDeploymentTrialMutationVariables>;
export const DeleteDeploymentDocument = gql`
    mutation DeleteDeployment($id: ID!) {
  deployment: deleteDeployment(id: $id) {
    id
  }
}
    `;
export type DeleteDeploymentMutationFn = Apollo.MutationFunction<DeleteDeploymentMutation, DeleteDeploymentMutationVariables>;

/**
 * __useDeleteDeploymentMutation__
 *
 * To run a mutation, you first call `useDeleteDeploymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeploymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeploymentMutation, { data, loading, error }] = useDeleteDeploymentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDeploymentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDeploymentMutation, DeleteDeploymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDeploymentMutation, DeleteDeploymentMutationVariables>(DeleteDeploymentDocument, options);
      }
export type DeleteDeploymentMutationHookResult = ReturnType<typeof useDeleteDeploymentMutation>;
export type DeleteDeploymentMutationResult = Apollo.MutationResult<DeleteDeploymentMutation>;
export type DeleteDeploymentMutationOptions = Apollo.BaseMutationOptions<DeleteDeploymentMutation, DeleteDeploymentMutationVariables>;
export const UpdateDeploymentDocument = gql`
    mutation UpdateDeployment($id: ID!, $data: DeploymentUpdateInput!) {
  updateDeployment(id: $id, data: $data) {
    id
    name
    description
  }
}
    `;
export type UpdateDeploymentMutationFn = Apollo.MutationFunction<UpdateDeploymentMutation, UpdateDeploymentMutationVariables>;

/**
 * __useUpdateDeploymentMutation__
 *
 * To run a mutation, you first call `useUpdateDeploymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeploymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeploymentMutation, { data, loading, error }] = useUpdateDeploymentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDeploymentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeploymentMutation, UpdateDeploymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeploymentMutation, UpdateDeploymentMutationVariables>(UpdateDeploymentDocument, options);
      }
export type UpdateDeploymentMutationHookResult = ReturnType<typeof useUpdateDeploymentMutation>;
export type UpdateDeploymentMutationResult = Apollo.MutationResult<UpdateDeploymentMutation>;
export type UpdateDeploymentMutationOptions = Apollo.BaseMutationOptions<UpdateDeploymentMutation, UpdateDeploymentMutationVariables>;
export const CreateLicenseDocument = gql`
    mutation CreateLicense($data: LicenseCreateInput!) {
  createLicense(data: $data) {
    id
  }
}
    `;
export type CreateLicenseMutationFn = Apollo.MutationFunction<CreateLicenseMutation, CreateLicenseMutationVariables>;

/**
 * __useCreateLicenseMutation__
 *
 * To run a mutation, you first call `useCreateLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLicenseMutation, { data, loading, error }] = useCreateLicenseMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateLicenseMutation(baseOptions?: Apollo.MutationHookOptions<CreateLicenseMutation, CreateLicenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLicenseMutation, CreateLicenseMutationVariables>(CreateLicenseDocument, options);
      }
export type CreateLicenseMutationHookResult = ReturnType<typeof useCreateLicenseMutation>;
export type CreateLicenseMutationResult = Apollo.MutationResult<CreateLicenseMutation>;
export type CreateLicenseMutationOptions = Apollo.BaseMutationOptions<CreateLicenseMutation, CreateLicenseMutationVariables>;
export const CreateLicenseOrderDocument = gql`
    mutation CreateLicenseOrder($data: LicenseOrderCreateInput!) {
  createLicenseOrder(data: $data) {
    id
  }
}
    `;
export type CreateLicenseOrderMutationFn = Apollo.MutationFunction<CreateLicenseOrderMutation, CreateLicenseOrderMutationVariables>;

/**
 * __useCreateLicenseOrderMutation__
 *
 * To run a mutation, you first call `useCreateLicenseOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLicenseOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLicenseOrderMutation, { data, loading, error }] = useCreateLicenseOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateLicenseOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateLicenseOrderMutation, CreateLicenseOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLicenseOrderMutation, CreateLicenseOrderMutationVariables>(CreateLicenseOrderDocument, options);
      }
export type CreateLicenseOrderMutationHookResult = ReturnType<typeof useCreateLicenseOrderMutation>;
export type CreateLicenseOrderMutationResult = Apollo.MutationResult<CreateLicenseOrderMutation>;
export type CreateLicenseOrderMutationOptions = Apollo.BaseMutationOptions<CreateLicenseOrderMutation, CreateLicenseOrderMutationVariables>;
export const DeploymentDocument = gql`
    query Deployment($id: ID!) {
  deployment(id: $id) {
    id
    type
    name
    creatorId
    organizationId
    description
    terraformWorkspaceId
    sfdcAccountId
    sfdcOpportunityId
    pocExpiresAt
    contractExpiresAt
    createdAt
    licenses(first: 5) {
      nodes {
        id
        license
        trial
        deploymentId
        maxStorageGb
        maxTeams
        maxUsers
        maxViewOnlyUsers
        maxRegisteredModels
        createdAt
        expiresAt
        flags {
          flag
          name
          description
        }
      }
    }
    releases {
      id
      message
      createdAt
      canceledAt
      refresh
      status
      statusTimestamps {
        appliedAt
        erroredAt
      }
    }
  }
}
    `;

/**
 * __useDeploymentQuery__
 *
 * To run a query within a React component, call `useDeploymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeploymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeploymentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeploymentQuery(baseOptions: Apollo.QueryHookOptions<DeploymentQuery, DeploymentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeploymentQuery, DeploymentQueryVariables>(DeploymentDocument, options);
      }
export function useDeploymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeploymentQuery, DeploymentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeploymentQuery, DeploymentQueryVariables>(DeploymentDocument, options);
        }
export type DeploymentQueryHookResult = ReturnType<typeof useDeploymentQuery>;
export type DeploymentLazyQueryHookResult = ReturnType<typeof useDeploymentLazyQuery>;
export type DeploymentQueryResult = Apollo.QueryResult<DeploymentQuery, DeploymentQueryVariables>;
export const DeploymentLicenseDocument = gql`
    query DeploymentLicense($deploymentId: ID!) {
  deployment(id: $deploymentId) {
    id
    licenses(first: 1) {
      nodes {
        id
        deploymentId
        license
      }
    }
  }
}
    `;

/**
 * __useDeploymentLicenseQuery__
 *
 * To run a query within a React component, call `useDeploymentLicenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeploymentLicenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeploymentLicenseQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *   },
 * });
 */
export function useDeploymentLicenseQuery(baseOptions: Apollo.QueryHookOptions<DeploymentLicenseQuery, DeploymentLicenseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeploymentLicenseQuery, DeploymentLicenseQueryVariables>(DeploymentLicenseDocument, options);
      }
export function useDeploymentLicenseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeploymentLicenseQuery, DeploymentLicenseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeploymentLicenseQuery, DeploymentLicenseQueryVariables>(DeploymentLicenseDocument, options);
        }
export type DeploymentLicenseQueryHookResult = ReturnType<typeof useDeploymentLicenseQuery>;
export type DeploymentLicenseLazyQueryHookResult = ReturnType<typeof useDeploymentLicenseLazyQuery>;
export type DeploymentLicenseQueryResult = Apollo.QueryResult<DeploymentLicenseQuery, DeploymentLicenseQueryVariables>;
export const DeploymentLicensesDocument = gql`
    query DeploymentLicenses($deploymentId: ID!) {
  deployment(id: $deploymentId) {
    id
    licenses(first: 50) {
      nodes {
        id
        flags {
          name
          flag
          description
        }
        maxUsers
        maxViewOnlyUsers
        maxStorageGb
        maxTeams
        maxRegisteredModels
        trial
        createdAt
        expiresAt
        deploymentId
        license
      }
    }
  }
}
    `;

/**
 * __useDeploymentLicensesQuery__
 *
 * To run a query within a React component, call `useDeploymentLicensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeploymentLicensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeploymentLicensesQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *   },
 * });
 */
export function useDeploymentLicensesQuery(baseOptions: Apollo.QueryHookOptions<DeploymentLicensesQuery, DeploymentLicensesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeploymentLicensesQuery, DeploymentLicensesQueryVariables>(DeploymentLicensesDocument, options);
      }
export function useDeploymentLicensesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeploymentLicensesQuery, DeploymentLicensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeploymentLicensesQuery, DeploymentLicensesQueryVariables>(DeploymentLicensesDocument, options);
        }
export type DeploymentLicensesQueryHookResult = ReturnType<typeof useDeploymentLicensesQuery>;
export type DeploymentLicensesLazyQueryHookResult = ReturnType<typeof useDeploymentLicensesLazyQuery>;
export type DeploymentLicensesQueryResult = Apollo.QueryResult<DeploymentLicensesQuery, DeploymentLicensesQueryVariables>;
export const DeploymentStateDocument = gql`
    query DeploymentState($id: ID!) {
  deployment(id: $id) {
    id
    workspace {
      id
      name
      versionControl {
        branch
        identifier
      }
    }
    state {
      id
      resources {
        count
        module
        name
        provider
        type
      }
      outputs {
        name
        sensitive
        type
        value
      }
    }
  }
}
    `;

/**
 * __useDeploymentStateQuery__
 *
 * To run a query within a React component, call `useDeploymentStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeploymentStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeploymentStateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeploymentStateQuery(baseOptions: Apollo.QueryHookOptions<DeploymentStateQuery, DeploymentStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeploymentStateQuery, DeploymentStateQueryVariables>(DeploymentStateDocument, options);
      }
export function useDeploymentStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeploymentStateQuery, DeploymentStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeploymentStateQuery, DeploymentStateQueryVariables>(DeploymentStateDocument, options);
        }
export type DeploymentStateQueryHookResult = ReturnType<typeof useDeploymentStateQuery>;
export type DeploymentStateLazyQueryHookResult = ReturnType<typeof useDeploymentStateLazyQuery>;
export type DeploymentStateQueryResult = Apollo.QueryResult<DeploymentStateQuery, DeploymentStateQueryVariables>;
export const DeploymentVariablesDocument = gql`
    query DeploymentVariables($id: ID!) {
  deployment(id: $id) {
    id
    variables {
      id
      key
      value
      category
      sensitive
      hcl
    }
  }
}
    `;

/**
 * __useDeploymentVariablesQuery__
 *
 * To run a query within a React component, call `useDeploymentVariablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeploymentVariablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeploymentVariablesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeploymentVariablesQuery(baseOptions: Apollo.QueryHookOptions<DeploymentVariablesQuery, DeploymentVariablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeploymentVariablesQuery, DeploymentVariablesQueryVariables>(DeploymentVariablesDocument, options);
      }
export function useDeploymentVariablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeploymentVariablesQuery, DeploymentVariablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeploymentVariablesQuery, DeploymentVariablesQueryVariables>(DeploymentVariablesDocument, options);
        }
export type DeploymentVariablesQueryHookResult = ReturnType<typeof useDeploymentVariablesQuery>;
export type DeploymentVariablesLazyQueryHookResult = ReturnType<typeof useDeploymentVariablesLazyQuery>;
export type DeploymentVariablesQueryResult = Apollo.QueryResult<DeploymentVariablesQuery, DeploymentVariablesQueryVariables>;
export const DeploymentsDocument = gql`
    query Deployments($first: Int = 500, $where: DeploymentWhereInput, $includeLicenseInfo: Boolean = false, $includeReleases: Boolean = false, $includeWorkspace: Boolean = false) {
  deployments(first: $first, where: $where) {
    nodes {
      id
      type
      name
      terraformWorkspaceId
      description
      organizationId
      createdAt
      workspace @include(if: $includeWorkspace) {
        id
        name
        versionControl {
          branch
          identifier
        }
      }
      releases @include(if: $includeReleases) {
        id
        createdAt
        message
        status
      }
      licenses(first: 1) @include(if: $includeLicenseInfo) {
        nodes {
          id
          license
          trial
          deploymentId
          maxStorageGb
          maxTeams
          maxUsers
          maxViewOnlyUsers
          createdAt
          expiresAt
          flags {
            flag
            name
            description
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useDeploymentsQuery__
 *
 * To run a query within a React component, call `useDeploymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeploymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeploymentsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      where: // value for 'where'
 *      includeLicenseInfo: // value for 'includeLicenseInfo'
 *      includeReleases: // value for 'includeReleases'
 *      includeWorkspace: // value for 'includeWorkspace'
 *   },
 * });
 */
export function useDeploymentsQuery(baseOptions?: Apollo.QueryHookOptions<DeploymentsQuery, DeploymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeploymentsQuery, DeploymentsQueryVariables>(DeploymentsDocument, options);
      }
export function useDeploymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeploymentsQuery, DeploymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeploymentsQuery, DeploymentsQueryVariables>(DeploymentsDocument, options);
        }
export type DeploymentsQueryHookResult = ReturnType<typeof useDeploymentsQuery>;
export type DeploymentsLazyQueryHookResult = ReturnType<typeof useDeploymentsLazyQuery>;
export type DeploymentsQueryResult = Apollo.QueryResult<DeploymentsQuery, DeploymentsQueryVariables>;
export const AllFlagsDocument = gql`
    query AllFlags {
  flags: licenseFlags {
    flag
    description
    name
  }
}
    `;

/**
 * __useAllFlagsQuery__
 *
 * To run a query within a React component, call `useAllFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllFlagsQuery(baseOptions?: Apollo.QueryHookOptions<AllFlagsQuery, AllFlagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllFlagsQuery, AllFlagsQueryVariables>(AllFlagsDocument, options);
      }
export function useAllFlagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllFlagsQuery, AllFlagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllFlagsQuery, AllFlagsQueryVariables>(AllFlagsDocument, options);
        }
export type AllFlagsQueryHookResult = ReturnType<typeof useAllFlagsQuery>;
export type AllFlagsLazyQueryHookResult = ReturnType<typeof useAllFlagsLazyQuery>;
export type AllFlagsQueryResult = Apollo.QueryResult<AllFlagsQuery, AllFlagsQueryVariables>;
export const LicenseOrderDocument = gql`
    query LicenseOrder($id: ID!) {
  licenseOrder(id: $id) {
    id
    trial
    deploymentId
    maxUsers
    maxViewOnlyUsers
    maxStorageGb
    maxTeams
    maxRegisteredModels
    flags {
      flag
      name
      description
    }
    expiresAt
    createdAt
  }
}
    `;

/**
 * __useLicenseOrderQuery__
 *
 * To run a query within a React component, call `useLicenseOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useLicenseOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLicenseOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLicenseOrderQuery(baseOptions: Apollo.QueryHookOptions<LicenseOrderQuery, LicenseOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LicenseOrderQuery, LicenseOrderQueryVariables>(LicenseOrderDocument, options);
      }
export function useLicenseOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LicenseOrderQuery, LicenseOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LicenseOrderQuery, LicenseOrderQueryVariables>(LicenseOrderDocument, options);
        }
export type LicenseOrderQueryHookResult = ReturnType<typeof useLicenseOrderQuery>;
export type LicenseOrderLazyQueryHookResult = ReturnType<typeof useLicenseOrderLazyQuery>;
export type LicenseOrderQueryResult = Apollo.QueryResult<LicenseOrderQuery, LicenseOrderQueryVariables>;
export const DeleteLicenseOrderDocument = gql`
    mutation DeleteLicenseOrder($id: ID!) {
  deleteLicenseOrder(id: $id) {
    id
  }
}
    `;
export type DeleteLicenseOrderMutationFn = Apollo.MutationFunction<DeleteLicenseOrderMutation, DeleteLicenseOrderMutationVariables>;

/**
 * __useDeleteLicenseOrderMutation__
 *
 * To run a mutation, you first call `useDeleteLicenseOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLicenseOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLicenseOrderMutation, { data, loading, error }] = useDeleteLicenseOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLicenseOrderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLicenseOrderMutation, DeleteLicenseOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLicenseOrderMutation, DeleteLicenseOrderMutationVariables>(DeleteLicenseOrderDocument, options);
      }
export type DeleteLicenseOrderMutationHookResult = ReturnType<typeof useDeleteLicenseOrderMutation>;
export type DeleteLicenseOrderMutationResult = Apollo.MutationResult<DeleteLicenseOrderMutation>;
export type DeleteLicenseOrderMutationOptions = Apollo.BaseMutationOptions<DeleteLicenseOrderMutation, DeleteLicenseOrderMutationVariables>;
export const LicenseOrdersDocument = gql`
    query LicenseOrders($first: Int = 50, $where: LicenseOrderWhereInput) {
  licenseOrders(first: $first, where: $where) {
    nodes {
      id
      deployment {
        id
        name
        type
        createdAt
      }
      flags {
        flag
        name
        description
      }
      notes
      maxStorageGb
      maxUsers
      maxViewOnlyUsers
      maxTeams
      customerEmail
      claimedByUserId
      createdByUserId
      expiresAt
      createdAt
    }
  }
}
    `;

/**
 * __useLicenseOrdersQuery__
 *
 * To run a query within a React component, call `useLicenseOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useLicenseOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLicenseOrdersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLicenseOrdersQuery(baseOptions?: Apollo.QueryHookOptions<LicenseOrdersQuery, LicenseOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LicenseOrdersQuery, LicenseOrdersQueryVariables>(LicenseOrdersDocument, options);
      }
export function useLicenseOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LicenseOrdersQuery, LicenseOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LicenseOrdersQuery, LicenseOrdersQueryVariables>(LicenseOrdersDocument, options);
        }
export type LicenseOrdersQueryHookResult = ReturnType<typeof useLicenseOrdersQuery>;
export type LicenseOrdersLazyQueryHookResult = ReturnType<typeof useLicenseOrdersLazyQuery>;
export type LicenseOrdersQueryResult = Apollo.QueryResult<LicenseOrdersQuery, LicenseOrdersQueryVariables>;
export const NextLocalReleaseDocument = gql`
    query NextLocalRelease {
  nextLocalRelease {
    version
    cc {
      type
      scope
      subject
      breaking
    }
  }
}
    `;

/**
 * __useNextLocalReleaseQuery__
 *
 * To run a query within a React component, call `useNextLocalReleaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useNextLocalReleaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNextLocalReleaseQuery({
 *   variables: {
 *   },
 * });
 */
export function useNextLocalReleaseQuery(baseOptions?: Apollo.QueryHookOptions<NextLocalReleaseQuery, NextLocalReleaseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NextLocalReleaseQuery, NextLocalReleaseQueryVariables>(NextLocalReleaseDocument, options);
      }
export function useNextLocalReleaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NextLocalReleaseQuery, NextLocalReleaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NextLocalReleaseQuery, NextLocalReleaseQueryVariables>(NextLocalReleaseDocument, options);
        }
export type NextLocalReleaseQueryHookResult = ReturnType<typeof useNextLocalReleaseQuery>;
export type NextLocalReleaseLazyQueryHookResult = ReturnType<typeof useNextLocalReleaseLazyQuery>;
export type NextLocalReleaseQueryResult = Apollo.QueryResult<NextLocalReleaseQuery, NextLocalReleaseQueryVariables>;
export const ReleaseDocument = gql`
    query Release($id: ID!) {
  release(id: $id) {
    id
    message
    createdAt
    canceledAt
    refresh
    status
    isDestroy
    statusTimestamps {
      erroredAt
      appliedAt
    }
    events {
      target
      action
      createdAt
    }
    plan {
      status
      resourceAdditions
      resourceDestructions
      resourceChanges
      logs
    }
    apply {
      status
      resourceAdditions
      resourceDestructions
      resourceChanges
      logs
    }
  }
}
    `;

/**
 * __useReleaseQuery__
 *
 * To run a query within a React component, call `useReleaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useReleaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReleaseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReleaseQuery(baseOptions: Apollo.QueryHookOptions<ReleaseQuery, ReleaseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReleaseQuery, ReleaseQueryVariables>(ReleaseDocument, options);
      }
export function useReleaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReleaseQuery, ReleaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReleaseQuery, ReleaseQueryVariables>(ReleaseDocument, options);
        }
export type ReleaseQueryHookResult = ReturnType<typeof useReleaseQuery>;
export type ReleaseLazyQueryHookResult = ReturnType<typeof useReleaseLazyQuery>;
export type ReleaseQueryResult = Apollo.QueryResult<ReleaseQuery, ReleaseQueryVariables>;
export const ReleaseLogsDocument = gql`
    query ReleaseLogs($id: ID!) {
  release(id: $id) {
    id
    plan {
      logs
    }
    apply {
      logs
    }
  }
}
    `;

/**
 * __useReleaseLogsQuery__
 *
 * To run a query within a React component, call `useReleaseLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReleaseLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReleaseLogsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReleaseLogsQuery(baseOptions: Apollo.QueryHookOptions<ReleaseLogsQuery, ReleaseLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReleaseLogsQuery, ReleaseLogsQueryVariables>(ReleaseLogsDocument, options);
      }
export function useReleaseLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReleaseLogsQuery, ReleaseLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReleaseLogsQuery, ReleaseLogsQueryVariables>(ReleaseLogsDocument, options);
        }
export type ReleaseLogsQueryHookResult = ReturnType<typeof useReleaseLogsQuery>;
export type ReleaseLogsLazyQueryHookResult = ReturnType<typeof useReleaseLogsLazyQuery>;
export type ReleaseLogsQueryResult = Apollo.QueryResult<ReleaseLogsQuery, ReleaseLogsQueryVariables>;
export const ReleaseOutputsDocument = gql`
    query ReleaseOutputs($id: ID!) {
  release(id: $id) {
    id
    plan {
      logs
    }
    apply {
      logs
    }
  }
}
    `;

/**
 * __useReleaseOutputsQuery__
 *
 * To run a query within a React component, call `useReleaseOutputsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReleaseOutputsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReleaseOutputsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReleaseOutputsQuery(baseOptions: Apollo.QueryHookOptions<ReleaseOutputsQuery, ReleaseOutputsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReleaseOutputsQuery, ReleaseOutputsQueryVariables>(ReleaseOutputsDocument, options);
      }
export function useReleaseOutputsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReleaseOutputsQuery, ReleaseOutputsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReleaseOutputsQuery, ReleaseOutputsQueryVariables>(ReleaseOutputsDocument, options);
        }
export type ReleaseOutputsQueryHookResult = ReturnType<typeof useReleaseOutputsQuery>;
export type ReleaseOutputsLazyQueryHookResult = ReturnType<typeof useReleaseOutputsLazyQuery>;
export type ReleaseOutputsQueryResult = Apollo.QueryResult<ReleaseOutputsQuery, ReleaseOutputsQueryVariables>;
export const VersionsDocument = gql`
    query Versions {
  versions: imageVersions {
    count
    results {
      id
      name
      lastUpdated
    }
  }
}
    `;

/**
 * __useVersionsQuery__
 *
 * To run a query within a React component, call `useVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVersionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useVersionsQuery(baseOptions?: Apollo.QueryHookOptions<VersionsQuery, VersionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VersionsQuery, VersionsQueryVariables>(VersionsDocument, options);
      }
export function useVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VersionsQuery, VersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VersionsQuery, VersionsQueryVariables>(VersionsDocument, options);
        }
export type VersionsQueryHookResult = ReturnType<typeof useVersionsQuery>;
export type VersionsLazyQueryHookResult = ReturnType<typeof useVersionsLazyQuery>;
export type VersionsQueryResult = Apollo.QueryResult<VersionsQuery, VersionsQueryVariables>;