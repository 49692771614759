import { Box } from '@mui/material'
import { useTheme } from '@wandb/ui'
import { Card, CardContent } from 'common/Card'
import { useDisclosure } from 'common/hooks/useDisclosure'
import { DeploymentDialogLicense } from 'common/license/DeploymentDialogLicense'
import { useCopyLicenseEvent } from 'common/license/useLicenseCopy'
import { Page, PageContainer } from 'common/Page'
import { ReactTable } from 'common/ReactTable'
import { Section } from 'common/Section'
import { formatDistanceToNow, parseISO } from 'date-fns'
import { useDeploymentsQuery } from 'generated/deploy'
import { useOrganizationsSearchQuery } from 'generated/gorilla'
import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Column, useTable } from 'react-table'
import { useDebounce } from 'react-use'
import { Button, Input } from 'semantic-ui-react'

import { AdminHeader } from './components/AdminHeader'

const useDeploymentsSearch = () => {
  const [search, setSearch] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useState('')

  useDebounce(() => setDebouncedSearch(search), 500, [search])
  const orgSearch = useOrganizationsSearchQuery({
    variables: { search: debouncedSearch, first: 100 }
  })
  const companies = useMemo(
    () => orgSearch.data?.organizations.edges.map(e => e.node) ?? [],
    [orgSearch]
  )

  const companyIds = useMemo(() => companies.map(c => c.id), [companies])
  const variables = {
    where: { organizationId: { in: companyIds } },
    includeLicenseInfo: true
  }
  const { data, refetch } = useDeploymentsQuery({
    variables
  })

  const deployments = useMemo(
    () =>
      data?.deployments?.nodes.map(d => ({
        ...d,
        organization: companies.find(s => s.id === d.organizationId),
        license: d.licenses?.nodes?.[0]
      })),
    [data, companies]
  )

  return {
    search,
    setSearch,
    refetch: () => refetch(variables),
    companies,
    companyIds,
    deployments
  }
}

type DeploymentsTableEntry = {
  id: string
  name: string
  license?: {
    id: string
    deploymentId: string
    createdAt: string
    expiresAt: string
    trial: boolean
    license: string
  }
  organizationId: string
  organization?: { id: string; name: string }
}

type DeploymentsTableProps = {
  deployments: Array<DeploymentsTableEntry>
  onLicenseCreate?: () => void
}
const DeploymentsTable: React.FC<DeploymentsTableProps> = props => {
  const { deployments, onLicenseCreate } = props
  const { spacing } = useTheme()

  const columns = useMemo<Array<Column<DeploymentsTableEntry>>>(
    () => [
      {
        Header: 'Deployment',
        accessor: 'id',
        Cell: ({ row: { original } }) => (
          <>
            <Link to={`/org/${original.organizationId}`}>
              {original.organization?.name ?? <i>Unknown Organization</i>}
            </Link>{' '}
            /{' '}
            <Link to={`/${original.id}`} target="_blank">
              {original.name}
            </Link>
          </>
        )
      },
      {
        accessor: 'license',
        Header: 'Expires In',
        Cell: ({ value, row }) => {
          const { isOpen, onOpen, onClose } = useDisclosure()
          const [copied, setCopied] = useState(false)
          const copyLicenseEvent = useCopyLicenseEvent()
          const copy = () => {
            setCopied(true)
            setTimeout(() => setCopied(false), 2000)
          }

          useEffect(() => {
            const license = value
            if (license != null && copied) {
              navigator.clipboard.writeText(license.license)
              copyLicenseEvent(license)
            }
          }, [value, copied, copyLicenseEvent])

          return (
            <Box textAlign="right">
              {value &&
                formatDistanceToNow(parseISO(value.expiresAt), {
                  addSuffix: true
                })}
              <Button
                onClick={onOpen}
                basic
                size="mini"
                color="green"
                style={{ marginLeft: spacing(2) }}
              >
                Edit
              </Button>
              <DeploymentDialogLicense
                deploymentId={row.original.id}
                open={isOpen}
                onClose={onClose}
                onLicenseCreate={onLicenseCreate}
              />
              <Button
                onClick={e => {
                  e.stopPropagation()
                  copy()
                }}
                size="mini"
                color="green"
                icon={copied ? 'check' : 'clone'}
                content="Copy License"
              />
            </Box>
          )
        }
      }
    ],
    [spacing, onLicenseCreate]
  )
  const { colors } = useTheme()
  const table = useTable({
    data: deployments,
    columns
  })
  return (
    <ReactTable
      {...table}
      style={{
        border: 'none',
        borderTop: `1px solid ${colors.gray[300]}`,
        marginTop: 0
      }}
    />
  )
}

const SearchIndex: React.FC = () => {
  const { search, setSearch, deployments, refetch } = useDeploymentsSearch()
  return (
    <Page title="Admin - Search">
      <PageContainer maxWidth="xl">
        <AdminHeader />
        <Section style={{ maxWidth: 750 }}>
          <Card>
            <CardContent>
              <Input
                value={search}
                onChange={e => setSearch(e.target.value)}
                size="small"
                placeholder="Organization name"
                style={{ width: '100%' }}
              />
            </CardContent>
            <DeploymentsTable
              deployments={deployments ?? []}
              onLicenseCreate={refetch}
            />
          </Card>
        </Section>
      </PageContainer>
    </Page>
  )
}

export default SearchIndex
